<template>
  <app-cropper 
    :image="image" 
    :show="showCropperLogo" 
    :aspect_ratio= 16/9
    @result="resultCropper($event, MEDIA_CATEGORY.LOGO)" 
    @cancel="cancelCropper"
    @close="showCropperLogo = false" />
  <app-cropper
    :image="image"
    :show="showCropperIcon"
    @result="resultCropper($event, MEDIA_CATEGORY.ICON)"
    @cancel="cancelCropper"
    @close="showCropperIcon = false"
  />
  <div class="w-full mb-5">
    <h1 class="text-2xl font-bold">
      {{ $t("business.company_logo.title") }}
    </h1>
    <p class="mt-4">
      {{ $t("business.company_logo.description") }}
    </p>
  </div>
  <div class="w-full">
    <p class="mb-4">{{ $t("business.company_logo.icon") }}</p>
    <div
      class="
        space-y-5
        sm:flex-row
        flex flex-col
        place-items-center
        sm:place-items-stretch sm:space-y-0 sm:space-x-40
      "
    >
      <div
        class="
          w-26
          h-26
          relative
          rounded-md
          border-dashed
          cursor-pointer
          text-gray-400
          hover:bg-gray-50
          border border-bordercolor
        "
        @click="$refs.file_icon.click"
      >
        <div>
          <input
            hidden
            ref="file_icon"
            type="file"
            accept="image/*"
            @change="loadImage($event, MEDIA_CATEGORY.ICON)"
          />
          <app-image
            :src="icon"
            :loading="loading || businessLoading"
            class="rounded-md"
          />
        </div>

        <div
          v-if="!icon"
          class="
            absolute
            top-1/2
            left-1/2
            transform
            -translate-x-1/2 -translate-y-1/2
          "
        >
          <div class="items-center">
            <app-icon-outline name="PlusCircleIcon" class="w-6" />
          </div>
        </div>
      </div>
      <div>
        <div
          class="
            p-3
            w-full
            sm:w-96
            text-sm
            rounded-md
            bg-gray-100
            text-gray-500
          "
        >
          {{ $t("business.company_logo.label_icon") }}

          <app-button
            :showf70Icon="false"
            class="justify-between space-x-2"
            width="w-56 mt-2"
          >
            <span class="flex-none">
              <app-icon-loading v-if="loading" customClass="w-5 h-5" />
              <div v-else-if="business">
                <app-image
                  v-if="getBusinessIcon(business)"
                  :src="getBusinessIcon(business)"
                  class="rounded-full flex-none w-5 h-5 bg-white"
                />
                <app-icon
                  v-else
                  name="BriefcaseIcon"
                  class="h-5 w-5 flex-none"
                />
              </div>
              <app-icon v-else name="UserIcon" class="h-5 w-5" />
            </span>
            <p>
              {{ $t("business.company_logo.label_merchant_name") }}
            </p>
            <app-icon name="ChevronDownIcon" class="w-5 h-5" />
          </app-button>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-8 w-full">
    <p class="mb-4">{{ $t("business.company_logo.logo") }}</p>
    <div
      class="
        space-y-5
        sm:flex-row
        flex flex-col
        place-items-center
        sm:place-items-stretch sm:space-y-0 sm:space-x-5
      "
    >
      <div
        class="
          w-60
          h-34
          relative
          rounded-md
          border-dashed
          cursor-pointer
          text-gray-400
          hover:bg-gray-50
          border border-bordercolor
        "
        @click="$refs.file_logo.click"
      >
        <div>
          <input
            hidden
            ref="file_logo"
            type="file"
            accept="image/*"
            @change="loadImage($event, MEDIA_CATEGORY.LOGO)"
          />
          <app-image
            :src="logo"
            :loading="loading || businessLoading"
            class="rounded-md"
          />
        </div>

        <div
          v-if="!logo"
          class="
            absolute
            top-1/2
            left-1/2
            transform
            -translate-x-1/2 -translate-y-1/2
          "
        >
          <div class="items-center">
            <app-icon-outline name="PlusCircleIcon" class="w-6" />
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <p
          class="
            p-3
            w-full
            sm:w-96
            text-sm
            rounded-md
            bg-gray-100
            text-gray-500
          "
        >
          {{ $t("business.company_logo.label_logo") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import MEDIA_CATEGORY from "@/utils/const/media_category";
export default {
  data() {
    return {
      MEDIA_CATEGORY: MEDIA_CATEGORY,
      image: {
        src: null,
        type: null,
      },
      showCropperLogo: false,
      showCropperIcon: false,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["mediaStore/loading"];
    },
    errors() {
      return this.$store.getters["mediaStore/errors"];
    },
    business() {
      return this.$store.getters["masterBusinessStore/business"];
    },
    logo() {
      return this.$store.getters["masterBusinessStore/logo"];
    },
    icon() {
      return this.$store.getters["masterBusinessStore/icon"];
    },
  },
  methods: {
    loadImage(event, category) {
      const { files } = event.target;
      if (files && files[0]) {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        const reader = new FileReader();
        reader.readAsArrayBuffer(files[0]);
        reader.onload = (e) => {
          if (category == MEDIA_CATEGORY.LOGO) {
            this.showCropperLogo = true;
            this.showCropperIcon = false;
          } else {
            this.showCropperLogo = false;
            this.showCropperIcon = true;
          }

          this.image = {
            src: URL.createObjectURL(files[0]),
            type: "image/png",
          };
        };
      }
    },
    resultCropper(result, category) {
      this.clearInputFile();
      const canvas = result.canvas;
      let business = this.business;
      let self = this;


      if (canvas) {
        canvas.toBlob((blob) => {
          const form = new FormData();
          form.append("file", blob, ".png");

          this.$store
            .dispatch("mediaStore/uploadFile", {
              file: form,
            })
            .then((result) => {
              if (category == MEDIA_CATEGORY.LOGO) {
                business.logo_url = result.data.media_path;
              } else {
                business.icon_url = result.data.media_path;
              }
              self.$store
                .dispatch("masterBusinessStore/createOrUpdateMasterBusiness", business)
                .then((data) => {
                  self.$store.dispatch("authStore/refreshUser");
                  self.notify("Updated successfully");
                });
            });
        }, "image/png");
      }
    },
    getBusinessIcon(business) {
      return business.media?.find((media) => media.collection_name == "icon")
        ?.original_url;
    },
    cancelCropper() {
      this.image = {
        src: null,
        type: null,
      };
    },
    clearInputFile() {
      this.$refs.file_logo.value = null;
      this.$refs.file_icon.value = null;  
    },
    notify(message) {
      this.$notify(
        {
          group: "success",
          title: message,
        },
        3000
      );
    },
  },
  unmounted() {
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src);
    }
  },
};
</script>
